const DummyData = [
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/K/C/KC04230_1.jpeg",
      "coupon_offer_price": "37904.000000",
      "salePrice": "37904.0",
      "rating": "5",
      "price_range": "₹40,001 - ₹50,000",
      "new_price": "43059.150000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/K/C/KC04230_1.jpeg",
      "url": "https://www.candere.com/autumn-blooms-diamond-two-finger-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/K/C/KC04230_1.jpeg",
      "name": "Autumn Blooms Diamond Two Finger Ring",
      "product_segment": "NEW ARRIVALS",
      "id": "46595",
      "product_rating": "5",
      "basePrice": "37904.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/K/C/KC03276_1.jpeg",
      "coupon_offer_price": "26579.000000",
      "salePrice": "26579.0",
      "rating": "5",
      "price_range": "₹20,001 - ₹30,000",
      "new_price": "28917.250000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/K/C/KC03276_1.jpeg",
      "url": "https://www.candere.com/pristine-floral-diamond-two-finger-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/K/C/KC03276_1.jpeg",
      "name": "Pristine Floral Diamond Two Finger Ring",
      "product_segment": "NEW ARRIVALS",
      "id": "45659",
      "product_rating": "5",
      "basePrice": "26579.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/c/0/c012942_1_2_1.jpg",
      "coupon_offer_price": "23930.000000",
      "salePrice": "23930.0",
      "price_range": "₹10,001 - ₹20,000",
      "new_price": "26175.390000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/c/0/c012942_1_2_1.jpg",
      "url": "https://www.candere.com/classic-circle-diamond-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/c/0/c012942_1_2_1.jpg",
      "name": "Classic Circle Miracle Plate Diamond Ring",
      "id": "6303",
      "product_rating": "4 and up",
      "basePrice": "23930.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/C/0/C014793__1_1.jpeg",
      "coupon_offer_price": "28604.000000",
      "salePrice": "28604.0",
      "rating": "5",
      "price_range": "₹20,001 - ₹30,000",
      "new_price": "29990.510000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/C/0/C014793__1_1.jpeg",
      "url": "https://www.candere.com/promise-of-infinity-miracle-plate-diamond-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/C/0/C014793__1_1.jpeg",
      "name": "Promise of Infinity Miracle Plate Diamond Ring",
      "product_segment": "BESTSELLERS",
      "id": "8788",
      "product_rating": "5",
      "basePrice": "28604.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/c/0/c019993_1.jpeg",
      "coupon_offer_price": "20192.000000",
      "salePrice": "20192.0",
      "rating": "5",
      "price_range": "₹10,001 - ₹20,000",
      "new_price": "22455.030000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/c/0/c019993_1.jpeg",
      "url": "https://www.candere.com/hamsa-evil-eye-diamond-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/c/0/c019993_1.jpeg",
      "name": "Hamsa Evil Eye Diamond Ring",
      "product_segment": "OUR PICKS",
      "id": "31830",
      "product_rating": "5",
      "basePrice": "20192.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/c/0/c020095_1_1_2.jpeg",
      "coupon_offer_price": "27479.000000",
      "salePrice": "27479.0",
      "rating": "5",
      "price_range": "₹20,001 - ₹30,000",
      "new_price": "31264.620000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/c/0/c020095_1_1_2.jpeg",
      "url": "https://www.candere.com/circle-diamond-frame-evil-eye-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/c/0/c020095_1_1_2.jpeg",
      "name": "Circle Diamond Frame Evil Eye Ring",
      "product_segment": "BESTSELLERS",
      "id": "31836",
      "product_rating": "5",
      "basePrice": "27479.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/K/C/KC03594_1.jpeg",
      "coupon_offer_price": "20780.000000",
      "salePrice": "20780.0",
      "rating": "5",
      "price_range": "₹20,001 - ₹30,000",
      "new_price": "23118.350000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/K/C/KC03594_1.jpeg",
      "url": "https://www.candere.com/circle-flaire-diamond-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/K/C/KC03594_1.jpeg",
      "name": "Circle Flaire Diamond Ring",
      "product_segment": "NEW ARRIVALS",
      "id": "46094",
      "product_rating": "5",
      "basePrice": "20780.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/C/P/CPR0156_1_1_1.jpeg",
      "coupon_offer_price": "17364.000000",
      "salePrice": "17364.0",
      "rating": "5",
      "new_price": "17630.510000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/C/P/CPR0156_1_1_1.jpeg",
      "url": "https://www.candere.com/minimal-platinum-diamond-band-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/C/P/CPR0156_1_1_1.jpeg",
      "name": "Minimal Platinum Diamond Band Ring",
      "product_segment": "BESTSELLERS",
      "id": "34798",
      "product_rating": "5",
      "basePrice": "17364.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/K/C/KC03798_1_1.jpeg",
      "coupon_offer_price": "39736.000000",
      "salePrice": "39736.0",
      "rating": "5",
      "price_range": "₹40,001 - ₹50,000",
      "new_price": "44724.660000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/K/C/KC03798_1_1.jpeg",
      "url": "https://www.candere.com/spring-embrace-diamond-two-finger-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/K/C/KC03798_1_1.jpeg",
      "name": "Spring Embrace Diamond Two Finger Ring",
      "product_segment": "NEW ARRIVALS",
      "id": "46256",
      "product_rating": "5",
      "basePrice": "39736.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/K/C/KC03279_1.jpeg",
      "coupon_offer_price": "28876.000000",
      "salePrice": "28876.0",
      "rating": "5",
      "new_price": "32016.520000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/K/C/KC03279_1.jpeg",
      "url": "https://www.candere.com/exotic-floral-diamond-two-finger-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/K/C/KC03279_1.jpeg",
      "name": "Exotic Floral Diamond Two Finger Ring",
      "product_segment": "NEW ARRIVALS",
      "id": "45656",
      "product_rating": "5",
      "basePrice": "28876.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/K/C/KC03771_1_1.jpeg",
      "coupon_offer_price": "26696.000000",
      "salePrice": "26696.0",
      "rating": "5",
      "price_range": "₹20,001 - ₹30,000",
      "new_price": "29802.020000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/K/C/KC03771_1_1.jpeg",
      "url": "https://www.candere.com/clover-tale-diamond-two-finger-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/K/C/KC03771_1_1.jpeg",
      "name": "Clover Tale Diamond Two Finger Ring",
      "product_segment": "NEW ARRIVALS",
      "id": "46253",
      "product_rating": "5",
      "basePrice": "26696.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/C/P/CPR0161__1_1.jpg",
      "coupon_offer_price": "28631.000000",
      "salePrice": "28631.0",
      "rating": "5",
      "price_range": "₹20,001 - ₹30,000",
      "new_price": "30285.090000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/C/P/CPR0161__1_1.jpg",
      "url": "https://www.candere.com/aaron-solatire-platinum-band-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/C/P/CPR0161__1_1.jpg",
      "name": "Aaron Solitaire Platinum Band Ring",
      "product_segment": "BESTSELLERS",
      "id": "34642",
      "product_rating": "5",
      "basePrice": "28631.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/K/C/KC04389YG_1.jpeg",
      "coupon_offer_price": "21490.000000",
      "salePrice": "21490.0",
      "rating": "5",
      "price_range": "₹20,001 - ₹30,000",
      "new_price": "24162.770000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/K/C/KC04389YG_1.jpeg",
      "url": "https://www.candere.com/circle-illusion-diamond-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/K/C/KC04389YG_1.jpeg",
      "name": "Circle Illusion Diamond Ring",
      "product_segment": "NEW ARRIVALS",
      "id": "46898",
      "product_rating": "5",
      "basePrice": "21490.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/C/0/C020879__1_1.jpeg",
      "coupon_offer_price": "15841.000000",
      "salePrice": "15841.0",
      "rating": "5",
      "price_range": "₹10,001 - ₹20,000",
      "new_price": "18164.050000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/C/0/C020879__1_1.jpeg",
      "url": "https://www.candere.com/marquee-diamond-stackable-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/C/0/C020879__1_1.jpeg",
      "name": "Marquee Diamond Stackable Ring",
      "product_segment": "BESTSELLERS",
      "id": "33141",
      "product_rating": "5",
      "basePrice": "15841.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/c/0/c002723_1.jpg",
      "coupon_offer_price": "48566.000000",
      "salePrice": "48566.0",
      "price_range": "₹40,001 - ₹50,000",
      "new_price": "58577.130000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/c/0/c002723_1.jpg",
      "url": "https://www.candere.com/brenna-diamond-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/c/0/c002723_1.jpg",
      "name": "Brenna Diamond Ring",
      "product_segment": "BESTSELLERS",
      "id": "670",
      "product_rating": "4 and up",
      "basePrice": "48566.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/L/C/LCR0618_1.jpeg",
      "coupon_offer_price": "42548.000000",
      "salePrice": "42548.0",
      "rating": "5",
      "price_range": "₹40,001 - ₹50,000",
      "new_price": "47358.370000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/L/C/LCR0618_1.jpeg",
      "url": "https://www.candere.com/gloriaa-diamond-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/L/C/LCR0618_1.jpeg",
      "name": "Gloriaa Diamond Ring",
      "product_segment": "BESTSELLERS",
      "id": "41950",
      "product_rating": "5",
      "basePrice": "42548.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/C/0/C018388__1_1.jpeg",
      "coupon_offer_price": "25549.000000",
      "salePrice": "25549.0",
      "rating": "5",
      "price_range": "₹20,001 - ₹30,000",
      "new_price": "28312.640000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/C/0/C018388__1_1.jpeg",
      "url": "https://www.candere.com/spiral-diamond-stackable-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/C/0/C018388__1_1.jpeg",
      "name": "Spiral Diamond Stackable Ring",
      "product_segment": "BESTSELLERS",
      "id": "25415",
      "product_rating": "5",
      "basePrice": "25549.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/K/C/KC04422YG_1.jpeg",
      "coupon_offer_price": "18551.000000",
      "salePrice": "18551.0",
      "rating": "5",
      "price_range": "₹10,001 - ₹20,000",
      "new_price": "19664.760000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/K/C/KC04422YG_1.jpeg",
      "url": "https://www.candere.com/texture-essence-diamond-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/K/C/KC04422YG_1.jpeg",
      "name": "Texture Essence Diamond Ring",
      "product_segment": "NEW ARRIVALS",
      "id": "47282",
      "product_rating": "5",
      "basePrice": "18551.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/C/0/C020900__1_1.jpeg",
      "coupon_offer_price": "17993.000000",
      "salePrice": "17993.0",
      "rating": "5",
      "price_range": "₹10,001 - ₹20,000",
      "new_price": "20075.730000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/C/0/C020900__1_1.jpeg",
      "url": "https://www.candere.com/hanging-heart-diamond-stackable-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/C/0/C020900__1_1.jpeg",
      "name": "Hanging Heart Diamond Stackable Ring",
      "product_segment": "BESTSELLERS",
      "id": "32979",
      "product_rating": "5",
      "basePrice": "17993.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/K/C/KC02764_1.jpeg",
      "coupon_offer_price": "18412.000000",
      "salePrice": "18412.0",
      "rating": "5",
      "price_range": "₹20,001 - ₹30,000",
      "new_price": "20784.370000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/K/C/KC02764_1.jpeg",
      "url": "https://www.candere.com/ring-a-bell-diamond-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/K/C/KC02764_1.jpeg",
      "name": "Ring A Bell Diamond Ring",
      "id": "45046",
      "product_rating": "5",
      "basePrice": "18412.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/C/0/C020704__1_1.jpeg",
      "coupon_offer_price": "21885.000000",
      "salePrice": "21885.0",
      "rating": "5",
      "price_range": "₹20,001 - ₹30,000",
      "new_price": "25788.110000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/C/0/C020704__1_1.jpeg",
      "url": "https://www.candere.com/chevron-striped-diamond-v-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/C/0/C020704__1_1.jpeg",
      "name": "Chevron Striped Diamond V Ring",
      "product_segment": "BESTSELLERS",
      "id": "32919",
      "product_rating": "5",
      "basePrice": "21885.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/c/0/c012937_1_2_1.jpg",
      "coupon_offer_price": "24525.000000",
      "salePrice": "24525.0",
      "price_range": "₹10,001 - ₹20,000",
      "new_price": "27865.620000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/c/0/c012937_1_2_1.jpg",
      "url": "https://www.candere.com/radiant-halo-diamond-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/c/0/c012937_1_2_1.jpg",
      "name": "Radiant halo Miracle Plate diamond Ring",
      "product_segment": "BESTSELLERS",
      "id": "6301",
      "product_rating": "4 and up",
      "basePrice": "24525.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/c/0/c001869_1_2.jpg",
      "coupon_offer_price": "43886.000000",
      "salePrice": "43886.0",
      "rating": "5",
      "price_range": "₹75,001 - ₹1,00,000",
      "new_price": "47273.910000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/c/0/c001869_1_2.jpg",
      "url": "https://www.candere.com/silver-eye-diamond-engagement-platinum-ring.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/c/0/c001869_1_2.jpg",
      "name": "Silver Eye Diamond Engagement Platinum Ring",
      "product_segment": "HOT",
      "id": "23103",
      "product_rating": "5",
      "basePrice": "43886.0"
    },
    {
      "image": "https://www.candere.com/needtochange/media/klevu_images/310X310/L/C/LCR0594_1.jpeg",
      "coupon_offer_price": "28433.000000",
      "salePrice": "28433.0",
      "rating": "5",
      "price_range": "₹30,001 - ₹40,000",
      "new_price": "32641.730000",
      "candere_product_type": "Ring",
      "imageEmail": "https://www.candere.com/media/klevu_images/310X310/L/C/LCR0594_1.jpeg",
      "url": "https://www.candere.com/raelynn-diamond-rings.html",
      "material_type": "Diamond",
      "imageUrl": "https://www.candere.com/needtochange/media/klevu_images/310X310/L/C/LCR0594_1.jpeg",
      "name": "Raelynn Diamond Rings",
      "product_segment": "BESTSELLERS",
      "id": "41901",
      "product_rating": "5",
      "basePrice": "28433.0"
    }
      ]


      export default DummyData;