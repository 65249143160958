import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // Make sure this is imported
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBs8yJaik4gUq60A1JV92laVGw9gJ3SAF8",
  authDomain: "devijew-b1203.firebaseapp.com",
  projectId: "devijew-b1203",
  storageBucket: "devijew-b1203.appspot.com",
  messagingSenderId: "56644451590",
  appId: "1:56644451590:web:af93758dc4552772753287",
  measurementId: "G-D1LHPP42Y3"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Initialize Firestore
const storage = getStorage(app);

export { db, storage  };
